jQuery(function (){


    ipadetect();

    jQuery('html').addClass('javascript jquery'); // javascript and jquery markers


    jQuery('*[data-ineraction]').on('click', function (event) { // global 'todo' rules for all element's with attr 'data-ineraction' on click


      var _this = jQuery(this),
          data = _this.data('ineraction'),
          target = jQuery( _this.data('target') );

      switch (data) {

        case "switch":
          event.preventDefault();
          if(target.hasClass('on')) {
              target.removeClass('on');
              _this.removeClass('switch-on');
              jQuery('html').removeClass('switch_' + _this.data('target').substr(1) );
          } else {
              target.addClass('on');
              _this.addClass('switch-on')
              jQuery('html').addClass('switch_' + _this.data('target').substr(1) );
          }
          break;

        case "close":
          event.preventDefault();
          jQuery('html').removeClass('switch_' + _this.closest('.on').attr('id'));
          _this.closest('.on').removeClass('on');
          _this.closest('.expand').removeClass('expand');

          break;

        case "remove":
          event.preventDefault();
          _this.remove();
          target.remove();
          break;

        case "clone":
          event.preventDefault();
          target.after(target.clone()).next().addClass('clone').find('input').val('');
          break;

        case "pane":
          event.preventDefault();
          if(!target.hasClass('on')) {
            _this.closest('.panecanvas').find('.pane, *[data-ineraction="pane"]').removeClass('on');
            target.addClass('on');
            jQuery('*[data-target="' + _this.data('target') + '"]').addClass('on');
          }
        case "expand":
          event.preventDefault();
          var expadion = _this.closest('.expandion'),
              node = expadion.find('.expandion-node'),
              thisNode = _this.closest('.expandion-node');
          if(thisNode.hasClass('expand')) {
            thisNode.removeClass('expand');
          } else {
            node.removeClass('expand');
            thisNode.addClass('expand');
          }
          // <div class="expandion">
          //   <div class="expandion-node">
          //     <a href="#!" data-ineraction="expand">Click Here</a>
          //     <div class="expandion-content">And see what here</div>
          //  </div>
          // </div>
          break;

        case "scrollto":
          event.preventDefault();
          var t;
          if(jQuery(window).width() >= 768) {
            t = 80;
          } else {
            t = 70;
          }
          jQuery('html, body').animate({scrollTop: jQuery(target).offset().top - t}, 500);
          break;

        case "modal":
          modals(target);

          break;
      }

    }).on('mouseenter', function (event) { // global 'todo' rules for all element's with attr 'data-ineraction' on click
          event.preventDefault();

          var _this = jQuery(this),
              data = _this.data('ineraction'),
              target = $( _this.data('target') );

          switch (data) {

            case "highlight":
              target.addClass('highlight');
              break;




          }

        }).on('mouseout', function (event) {
            event.preventDefault();

            var _this = jQuery(this),
                data = _this.data('ineraction'),
                target = $( _this.data('target') );

            switch (data) {

                case "highlight":
                  target.removeClass('highlight');
                break;


            }

        });

        equalHeight('.events-list a');
        equalHeight('.entertaiment-list a');

    iiReplace();

    jQuery(document).on('scroll', function () {
      fireScroll(['#page-header']);
    });

    owls();


    if(jQuery('.slider-products-vertical').length) {
        vertical_carousel();
    }




    jQuery(window).on('load resize', function () {


      flexheight();
      stickyfooter();




    });

    productPackgas();

    dotsInit();

});

/*** OUT DOM READY RANK ***/

function iiReplace() {
  jQuery('.title').each(function () {

      var str = jQuery(this).html();
      var array = str.split('');
      array.forEach(function (elem, index, array) {
        if(elem === 'і') {
          elem = 'i';
        } else if(elem === 'І') {
          elem = 'i';
        } else if(elem === 'ї') {
          elem = 'i';
        } else if(elem === 'Ї') {
          elem = 'i';
        }
      });


  });




}


function equalHeight(group) {
        jQuery(group).matchHeight({
          byRow: false
        });
  }


function dotsInit() {
  var ddd1 = jQuery('.events-list li a .text');
  var ddd2 = jQuery('.events-list li a .title');
  ddd1.dotdotdot();
  ddd2.dotdotdot();
  jQuery(window).on('load resize', function () {
    ddd1.trigger('destroy.dot').dotdotdot();
    ddd2.trigger('destroy.dot').dotdotdot();
  });
}

function productPackgas() {
  var co_type = jQuery('.co-select a');
  var pack_type = jQuery('*[data-pack]');

  co_type.on('click', function (e) {
    var that = jQuery(this),
        co = that.parents('.pane').find('.product-co'),
        unco = that.parents('.pane').find('.product-unco');
    e.preventDefault();
    switch (that.attr('href')) {
      case "#co":
        unco.removeClass('on');
        co.addClass('on');
        break;
      case "#unco":
      unco.addClass('on');
      co.removeClass('on');
        break;

    }
    co_type.removeClass('on');
    jQuery(this).addClass('on');
  });

  pack_type.on('click', function (e) {
    e.preventDefault();
    var that = jQuery(this);
        bulk = that.data('pack');

    that.parents('.product-co-type').find(pack_type).removeClass('on');
    that.parents('.product-co-type').find('*[data-pack="' + bulk + '"]').addClass('on');
  });




}





function flexheight() { // make height like width for any block
  var fh = jQuery('.flexheight');
  fh.each(function () {
    var _this = jQuery(this);
          _this.css({
            'display':'block'
          }).outerHeight(_this.outerWidth());
  })
}

function fireScroll(arr) {
  //console.log(int);
  if(arr !== undefined) {
    arr.forEach(function (elem, index, array) {
      var _this = jQuery(elem),
          firescroll = _this.data('firescroll') || 1;
      if(jQuery(document).scrollTop() > firescroll) {
        _this.addClass('firescroll');
      } else {
        _this.removeClass('firescroll');
      }

    });
  }
}

function stickyfooter() {
  var main = jQuery('#page-main'),
      h = jQuery(window).height() - jQuery('footer').outerHeight();
  main.css('min-height', h);
}

function owls() {

  var side = jQuery('.side'),
      owl = jQuery('*[data-ineraction=owl]'),
      settings;
  side.on('click', function (event) {
    event.preventDefault();
    if(jQuery(this).hasClass('prev')) {
      jQuery(this).parent().find('.owl-carousel').trigger('prev.owl');
    } else {
      jQuery(this).parent().find('.owl-carousel').trigger('next.owl');
    }
  });
  if(owl) {
    owl.each(function () {
      var _this = jQuery(this);
      switch (_this.parent().attr('id')) {
        case "slider-welcome":
          settings = { items: 1,
            dots:true,
            nav: true,
            navText: ['<i class="material-icons">&#xE314;</i>', '<i class="material-icons">&#xE315;</i>' ]
          };
          break;
        case "slider-events":
          settings = {
            margin: 20,
            responsive: {
              0: {
                items: 1
              },
              768: {
                items: 2
              },
              998: {
                items: 3
              }
            }
          }
          break;
        case "slider-products":
          settings = {
            responsive: {
              0: {
                items: 4
              },
              768: {
                items: 4
              }
            },
            margin: 10,
            dots:false,
            nav: true,
            navText: ['<i class="material-icons">&#xE314;</i>', '<i class="material-icons">&#xE315;</i>' ]
          };
          break;
      }
      switch (_this.parent().attr('class')) {
        case "article-slider":
          settings = {
            items: 1,
            loop: false,
            dots:false,
            nav: true,
            navText: ['<i class="material-icons">&#xE314;</i>', '<i class="material-icons">&#xE315;</i>' ]
          }
          break;


      }
      _this.on('initialized.owl.carousel', function () {
        _this.addClass('on');
        var str;
        if (_this.parent().hasClass('article-slider')) {
            str = _this.find('.owl-item.active .description').text() || ' ';
            _this.closest('.article-slider').find('.description-index p').text(str);
            _this.closest('.article-slider').find('.description-index span').text( ( +_this.find('.owl-item.active').index() + 1 ) + ' / '+  _this.closest('.article-slider').find('li').length);
        }
      }).on('translated.owl.carousel', function () {
        var str;
        if (_this.parent().hasClass('article-slider')) {
          str = _this.find('.owl-item.active .description').text() || ' ';
          _this.closest('.article-slider').find('.description-index p').text(str);
        _this.closest('.article-slider').find('.description-index span').text( ( +_this.find('.owl-item.active').index() + 1 ) + ' / '+  _this.closest('.article-slider').find('li').length);
        }
      }).owlCarousel(settings);
    });



  }

}

function modals(elem) {
  elem.arcticmodal({
    overlay: {
        css: {
            backgroundColor: '#1f272a',
            opacity: .75
        }
    },
    beforeOpen: function () {
      jQuery('body').addClass('modal-open ' + elem.attr('id') + '-open');
    },
    afterClose: function () {
      jQuery('body').removeClass('modal-open ' + elem.attr('id') + '-open');
    }
  });
}

function ipadetect() {
  if(navigator.userAgent.match(/iPad/i) != null) {
    jQuery('html').addClass('ipad');
  }
}

function vertical_carousel() {
	var carousel = jQuery('.slider-products-vertical'),
		list = carousel.find('.slider-wrap ul'),
		item = list.find('li'),
		controls = carousel.find('.material-icons'),
		amount = item.length;
		if(amount > 4) {
			carousel.addClass('first').find('.material-icons.prev').addClass('disabled');
			var counter = 4,
				max = amount,
				min = 4,
				top = 0;
			controls.on('click', function (e) {
				e.preventDefault();
				var this_e = jQuery(this);
				if(counter >= min && counter <= max) {
					if(this_e.hasClass('prev') && counter > min ) {
						--counter;
						top = top + 130;
						carousel.removeClass('last').find('.next').removeClass('disabled');
					} else if(this_e.hasClass('next') && counter < max) {
						++counter;
						top = top - 130;
						carousel.removeClass('first').find('.prev').removeClass('disabled');
					}
					setTimeout(function() {
						if(counter == min) {
							carousel.addClass('first').find('.prev').addClass('disabled');
						} else if(counter == max) {
							carousel.addClass('last').find('.next').addClass('disabled');
						}
					},50);
					list.css('top', top + 'px');
				}
			});
		}
}

// fixes
$(function () {
  if ($('.slider-wrap .list-unstyled>li').length<5) {
    $('.slider-products-vertical').find('.material-icons').hide();
  }
  if ($('#slider-products .owl-item').length<5) {
    $('#slider-products').find('.owl-controls').hide();
  }
});
